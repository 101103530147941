.thought-editor-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    transform: scale(0.95);
    transition: all 0.4s ease-in-out;
    z-index: 1000;
  }
  
  .thought-editor-container.show {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
  
  .thought-editor-content {
    background: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .editor-input {
    margin-top: 20px;
  }
  
  .editor-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  