/* Profile Header Container */
.profile-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #cdc5c5, #d2b2b2); /* Lighter background to contrast the universal header */
  padding: 30px 0;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out;
}

/* Avatar Container */
.profile-avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Avatar Styles */
.profile-head-avatar {
  border: 3px solid #ff6b6b; /* Add a border with accent color */
}

/* Username Styling */
.profile-username {
  font-size: 32px;
  font-weight: bold;
  color: #333; /* Black text */
  margin: 10px;
}

/* Follow Button */
.follow-button {
  background: linear-gradient(45deg, #ff6b6b, #ffafbd); /* Gradient accent */
  color: #fff;
  font-weight: 600;
  text-transform: none;
  border-radius: 20px;
  padding: 10px 25px;
  transition: background 0.3s ease-in-out;
  margin-top: 15px;
}

/* Hover Effect for Follow Button */
.follow-button:hover {
  background: linear-gradient(45deg, #ff4b4b, #ff7f8c); /* Darker gradient on hover */
}
