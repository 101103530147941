.thought-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .empty-message {
    text-align: center;
    color: #888;
    font-style: italic;
    padding: 20px;
  }
  
  .thought-list-container {
    display: flex;
    flex-direction: column;
  }
  
  .filter-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .filter-btn {
    padding: 8px 15px;
    border: 1px solid #ddd;
    background: #000000;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .filter-btn:hover {
    background: #777;
  }
  
  .filter-btn.active {
    background: #ff6b6b;
    color: white;
  }
  
  .sorting-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .sorting-container label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  #sort {
    padding: 4px;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #ff6b6b;
  }

  @media (max-width: 550px) {
    .filter-bar {
      flex-direction: column;
    }
    .sorting-container{
      justify-content: space-between;
      padding:0px 10px;
    }
  }