/* General Landing Page Styling */
.landing-container {
    padding: 10px 50px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: #333; /* Primary black color for text */
  }
  
  /* Header Section */
  .landing-header {
    background-color: #000; /* Primary black background */
    padding: 50px 20px;
    border-radius: 12px;
    color: #fff; /* White text */
    margin-bottom: 40px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .landing-title {
    font-size: 48px;
    font-weight: 700;
  }
  
  .landing-subtitle {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .login-button {
    background: linear-gradient(45deg, #ff6b6b, #ffafbd); /* Accent color */
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .login-button:hover {
    background-color: #ffafbd;
    color: white;
  }
  
  /* Content Section */
  .landing-content {
    text-align: left;
    margin-top: 20px;
  }
  
  .landing-content h2 {
    font-size: 28px;
    color: #000; /* Primary black */
    margin-bottom: 10px;
  }
  
  .landing-content p,
  .landing-content ul {
    font-size: 18px;
    line-height: 1.8;
    color: #333; /* Primary black */
  }
  
  .landing-content ul {
    list-style-type: disc;
    margin-left: 40px;
  }
  