.header {
    background: linear-gradient(45deg, #ff6b6b, #ffafbd);
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 100;
  }
  
  .header-toolbar {
    display: flex;
    justify-content: space-between;
  }
  
  .header-right {
    display: flex;
    align-items: center;
  }
  
  .profile-avatar {
    margin: 8px;
    cursor: pointer;
    background: #ffffff;
    color: #ff6b6b;
  }
  
  /* Mobile Header */
  @media (max-width: 400px) {
    .header {
      padding: 5px;
    }
  
    .header-right {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  