.error-message {
    color: red;
    margin: 10px 0;
    font-size: 14px;
  }
  
  .success-message {
    color: green;
    margin: 10px 0;
    font-size: 14px;
  }
  
  .input-field {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
  }
  