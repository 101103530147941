.dialog-content {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .dialog-content.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  