.floating-btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(45deg, #ff6b6b, #ffafbd);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .floating-btn:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    background: linear-gradient(45deg, #ff4b4b, #f5f7fa);
  }
  
  
  .thought-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    width: 500px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  }

    
@media (max-width: 600px) {
.floating-btn {
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
}
}
  