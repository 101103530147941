.login-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
    height: calc(100vh - 84px);
  }
  
  .login-card {
    background: white;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    max-width: 90%;
    text-align: center;
  }
  
  h1 {
    margin-bottom: 20px;
  }
  
  .toggle-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .toggle-btn {
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 16px;
    margin: 0 5px;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s ease;
  }
  
  .toggle-btn:hover {
    border-bottom: 2px solid #ff6b6b;
  }
  
  .toggle-btn.active {
    border-bottom: 2px solid #ff6b6b;
    font-weight: bold;
  }
  
  .input-field {
    margin: 15px 0;
    width: 100%;
  }
  
  @media (max-width: 1024px) {
    .login-page-container {
        height: calc(100vh - 74px);
      }
  }
  
  /* Mobile View */
  @media (max-width: 600px) {
    .login-page-container {
        height: calc(100vh - 66px);
    }
  }