/* Base Styles */
.profile-page {
    margin: 15px;
    padding: 10px 50px;
    position: relative;
  }
  
  .filter-toggle {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .success-message {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: opacity 0.5s ease-in-out;
  }
  
  .success-message.hidden {
    opacity: 0;
    visibility: hidden;
  }
  
  
  /* Tablet View */
  @media (max-width: 1024px) {
    .profile-page {
        padding: 10px 50px;
    }
  
    .profile-header {
      flex-direction: column;
      align-items: center;
    }
  }
  
  /* Mobile View */
  @media (max-width: 600px) {
    .profile-page {
      padding: 10px 25px;
    }
  
    .profile-header {
      padding: 15px;
    }
  }
  