.loading-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    color: #fff;
    flex-direction: column;
  }
  
  .spinner {
    color: #ff6b6b;
    margin-bottom: 15px;
  }
  
  .loading-message {
    font-size: 18px;
    font-weight: 600;
  }
  