.thought-card {
    transition: all 0.3s ease-in-out;
    border: 0.5px solid #ddd;
  }

  
  .thought-card:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .private-card {
    border: 1px solid #f50057;
    background: #fdefef;
  }
  
  .thought-username {
    color: #333;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    font-weight: bold;
    font-size: 18px;
  }
  
  .thought-username:hover {
    color: #3c1f1f;
    text-decoration: underline;
  }
  
  .thought-text {
    margin: 5px 0;
  }
  

  .action-bar {
    display: flex;
    justify-content: space-between; /* Align left and right actions on the same line */
    align-items: center; /* Vertically align the items */
    margin-top: 5px;
    flex-wrap: wrap;
  }

  .thought-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px
  }
  .upvote-actions {
    display: flex;
    justify-content: flex-start;
    position: relative;
    left: -16px;
    align-items: center;
  }
  .edit-btn,
  .delete-btn {
    transition: transform 0.2s ease-in-out;
  }
  
  .edit-btn:hover {
    transform: rotate(-15deg) scale(1.1);
  }
  
  .delete-btn:hover {
    transform: rotate(15deg) scale(1.1);
  }
  
  .private-icon {
    color: #f50057;
    padding: 8px;
  }


  #tpbutton:disabled {
    color:  #ff6b6b;
    padding: 8px;
  }

  #tpbutton {
    color:  #ff6b6b;
    padding: 8px
  } 

  #tpbutton1:disabled {
    color:  #888
  }
  
  