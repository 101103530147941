/* General Color Theme */
body, .homepage, .profile-page {
    color: #333; /* Use a dark grey for text */
    background-color: #f5f5f5; /* Light background */
    margin: 15px;
    padding: 10px 50px;
  }
  
  /* Success Message Styling */
  .success-message {
    background: linear-gradient(45deg, #ff6b6b, #ffafbd);
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: opacity 0.5s ease-in-out;
  }
  
  /* Redesign Filter Buttons */
  .filter-bar {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .filter-btn {
    padding: 8px 20px;
    border-radius: 5px;
    background: #f1f1f1;
    border: 1px solid #ddd;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .filter-btn:hover {
    background: #e0e0e0;
  }
  
  .filter-btn.active {
    background: linear-gradient(45deg, #ff6b6b, #ffafbd);
    color: white;
    font-weight: bold;
  }
  
  /* Thought Card Styling */
  .thought-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s ease-in-out;
  }
  
  .thought-card:hover {
    transform: scale(1.02);
  }
  
  .thought-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .thought-username {
    color: #333; /* Change username color to black */
    cursor: pointer;
    font-weight: bold;
  }
  
  .thought-text {
    margin-top: 10px;
    font-size: 16px;
    color: #444;
  }
  
  /* Floating Button Styling */
  .floating-btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(45deg, #ff6b6b, #ffafbd);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .floating-btn:hover {
    transform: scale(1.1);
  }
  
  /* Loading Spinner */
  .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #ff6b6b;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  /* Tablet View */
  @media (max-width: 1024px) {
    .homepage {
      margin: 10px;
      padding: 10px 50px;
    }
  
    .thought-card {
      padding: 12px;
      margin: 15px 0;
    }
  }
  
  /* Mobile View */
  @media (max-width: 600px) {
    .homepage {
      margin: 5px;
      padding: 5px 25px;
    }
  
    .thought-card {
      padding: 10px;
      margin: 10px 0;
    }
  
    .thought-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .thought-username {
      font-size: 16px;
      margin-bottom: 5px;
    }
  
    .timestamp {
      font-size: 12px;
    }
  
    .thought-text {
      font-size: 14px;
    }
  }

  
  .success-message.hidden {
    opacity: 0;
    visibility: hidden;
  }
  