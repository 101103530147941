body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

textarea {
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

button {
  padding: 10px 15px;
  background: #333;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background: #555;
}
